// rotating_logos.js
import $ from 'jquery';
import 'jquery-countdown';

$(document).ready(function() {
  // Define an array of logo image URLs
  var logoImages = [
    "https://placehold.it/150x150",
    "https://placehold.it/150x150",
    "https://placehold.it/150x150",
    "https://placehold.it/150x150",
    "https://placehold.it/150x150",
    "https://placehold.it/150x150",
    "https://placehold.it/150x150",
    "https://placehold.it/150x150",
    "https://placehold.it/150x150",
    "https://placehold.it/150x150"
    // Add more logo image URLs as needed
  ];

  // Get the logo container element
  var $logoContainer = $('.logo-rotation-container');

  // Start the logo rotation
  setInterval(rotateLogos, 1000); // Adjust the interval as desired

  function rotateLogos() {
    // Randomly select a logo from the array
    var randomLogo = logoImages[Math.floor(Math.random() * logoImages.length)];

    // Create a new logo element with the selected logo image
    var $newLogo = $('<img>').attr('src', randomLogo).hide();

    // Fade out the current logo
    $logoContainer.find('.logo').fadeOut('slow', function() {
      // Remove the current logo from the container
      $(this).remove();

      // Append the new logo to the container
      $logoContainer.append($newLogo);

      // Fade in the new logo
      $newLogo.fadeIn('slow');
    });
  }
});
